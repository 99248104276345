<template src="./process.html"></template>
<style scoped src="./process.css"></style>
<script>
import transactionService from '@/services/TransactionService.vue'
import Loading from 'vue-loading-overlay'
import transactionConstants from '@/constants/TransactionConstants.vue'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination.vue'

export default {  
    components : {
        Loading, modal, pagination
    },
    data () {
        return {
            customerEmail:null,
            referenceNumber: null,
            transactionDate:null,
            transactionDateTo:null,
            transactionId:null,
            searchBy:null,
            searchValue:null,
            columns :['DateTime','TransactionId','ParentReferenceNumber','SenderEmail','RecipientName','Amount','TransactionStatusDescription','Action'],
            options : {
                headings : {
                    DateTime : 'Transaction Time',
                    TransactionId: 'Transaction Id',
                    ParentReferenceNumber:'Parent Refernce Number',
                    SenderEmail: 'Sender Email',
                    RecipientName : 'Beneficiary Name' ,
                    Amount : 'Amount',
                    TransactionStatusDescription: 'Transaction Status',
                    Action : 'Action' 
                },
            },
            transactionSummary: [],
            loaderVisible : false,
            errorMessages: applicationConstants.formsMessages,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    methods : {
            async onShowTransactionDetails() {
            try {
                let dateValidation = true, fDate = null, tDate = null
                if(this.transactionDate !== null && this.transactionDateTo !== null ) {
                    fDate = moment(moment(this.transactionDate)._d).format('MM-DD-YYYY 00:00:00')
                    tDate = moment(moment(this.transactionDateTo)._d).format('MM-DD-YYYY 23:59:59')
                    if(fDate > tDate) {
                        this.showAlert(null, 'ToDate must be greater than FromDate')
                        dateValidation = false  
                    }
                }else if((this.transactionDate !== null && this.transactionDateTo === null)
                || (this.transactionDate === null && this.transactionDateTo !== null)){
                    this.$v.transactionDate.$touch()
                    this.$v.transactionDateTo.$touch()
                    dateValidation = false  
                }
                if(this.searchBy !== null) {
                    this.$v.searchValue.$touch()
                    if(this.$v.searchValue.$invalid) {
                        return
                    }
                }
                if(dateValidation) {
                    this.loaderVisible=true
                    this.customerEmail = this.searchBy === '0' ? this.searchValue : null
                    this.transactionId = this.searchBy === '1' ? this.searchValue : null
                    this.referenceNumber = this.searchBy === '2' ? this.searchValue : null
                    await transactionService.methods.GetTransactionSummary( {
                        CustomerEmail:this.customerEmail,
                        ReferenceNumber: this.referenceNumber,
                        TransactionDate:fDate,
                        TransactionDateTo:tDate,
                        TransactionId:this.transactionId,
                        TransactionType : '6',
                        TransactionStatus : '10'
                    })
                    .then(res => {
                        this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary?.csTransactionSummaryItem
                        if(this.transactionSummary) {
                            this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                        }else{
                            this.transactionSummary = []
                        }
                        if (this.enableLazyLoading) {
                            this.$refs.paginationRef.selectedPage = 1
                        }
                    })
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                        this.loaderVisible=false
                    })
                    this.customerEmail = null
                    this.transactionId = null
                    this.referenceNumber = null
                    this.loaderVisible=false
                    this.$v.$reset()
                }
            } catch (error) {
                this.loaderVisible=false
            }
        },
        filterSearchText() {
            this.searchValue = null
            this.$v.$reset()
        },
        cancelopr() {
            this.searchBy = null,
            this.searchValue = null,
            this.transactionDate = null,
            this.transactionDateTo = null
            this.$v.$reset()
        },
      async processTransaction(row) {
            try {
                this.$router.push({
                    name:'EFTTransaction',
                    params : {
                        email : row.SenderEmail,
                        Transactionid : row.TransactionId,
                        filters: {
                            'searchBy': this.searchBy,
                            'searchValue': this.searchValue,
                            'transactionDate': this.transactionDate,
                            'transactionDateTo': this.transactionDateTo
                        }
                    }
                })
            } catch (error) {
                // 
            }
        },
        cancelEftTransaction(referenceNumber, userEmail){
            try {
                this.$bvModal.msgBoxConfirm('Are you sure you want cancel EFT Transaction')
                .then(async value => {
                    if (value) {
                        this.loaderVisible = true
                       await transactionService.methods.CancelEFTTransaction(userEmail,referenceNumber)
                       .then(()=>{
                           this.transactionSummary = this.transactionSummary.filter(transaction => transaction.ReferenceNumber != referenceNumber)
                           this.loaderVisible=false
                           this.showAlert(1, 'EFT Transaction cancelled Successfully')
                        })
                        .catch(err => {
                            this.alertObject = err
                            this.$bvModal.show(this.$refs.childModal.id)
                        })
                    }               
                })
                .catch(err => {
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                })
            } catch (error) {
                //
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
     computed : {
         today: () => new Date(),
         transactionSummaryTable() {
            let reducer = (accumulator, currentValue) => {
                return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
            }, transactionSummary = []
            if (this.transactionSummary && this.transactionSummary.length) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if (this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    transactionSummary = transactionSummary.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                transactionSummary.forEach(transaction => {
                    Object.keys(transaction).map(key => {
                        transaction[key] = transaction[key]._text ? transaction[key]._text : null
                        if (key === 'TransactionStatus')
                            transaction['TransactionStatusDescription'] = transactionConstants.transactionStatus.reduce(reducer, transaction[key])
                    })
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return transactionSummary
         }
    },
    mounted () {
        let data = this.$route.params?.filters ? this.$route.params.filters : null
        if(data) {
            this.searchBy = data.searchBy? data.searchBy : null
            this.searchValue = data.searchValue ? data.searchValue : null
            this.transactionDate = data.transactionDate ? data.transactionDate : null
            this.transactionDateTo = data.transactionDateTo ? data.transactionDateTo : null
            this.onShowTransactionDetails()
        }
    },
    validations () {
        this.transactionDate = this.transactionDate === "" ? null : this.transactionDate
        this.transactionDateTo = this.transactionDateTo === '' ? null : this.transactionDateTo 
        this.searchBy = this.searchBy === 'null' ? null : this.searchBy
        return {
            searchValue: {
                required: requiredIf(function () {
                    return this.searchBy
                })
            },
            transactionDate: {
                required: requiredIf(function() {
                    return (!this.transactionDate && this.transactionDateTo) 
                })
            },
            transactionDateTo: {
                required: requiredIf(function() {
                    return (this.transactionDate && !this.transactionDateTo ) 
                })
            }
        }
    }, 
}
</script>
